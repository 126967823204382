import { PageProps } from 'gatsby';
import { ChannelData } from 'packages/innedit';
import React, { FC } from 'react';

import Form from '~/containers/Espace/Form';
import channelParams from '~/params/channel/css.json';
import TemplateAdmin from '~/templates/admin';
import requireUser, { UserProps } from '~/utils/requireUser';
import useChannel from '~/utils/useChannel';
import useEspace from '~/utils/useEspace';

const EspaceCanalCss: FC<PageProps & UserProps> = function ({
  location,
  user,
  params: { channelId, id: espaceId },
}) {
  const [espace] = useEspace({ id: espaceId, pathname: location.pathname });
  const [channel, items] = useChannel({
    channelId,
    espaceId,
    admin: true,
    pathname: location.pathname,
  });

  return (
    <TemplateAdmin>
      <Form
        displayArchive={false}
        displayRemove={false}
        docId={channelId}
        model={
          new ChannelData({
            espaceId,
            params: channelParams,
          })
        }
        params={{ channelId }}
        subMenu={items}
        title={`${espace?.label} ⇾ ${channel?.label}`}
        type="update"
      />
    </TemplateAdmin>
  );
};

export default requireUser(EspaceCanalCss, 'admin');
